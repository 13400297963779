import { apiCaller } from '../helpers/apiCaller';

export const getDetails = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/successlevel/details`, data);
}

export const getUserSolvedProgress = data => {
	return apiCaller({ needToken: true }).post(`${process.env.REACT_APP_API_URL}/api/userstatistic/getusersolvedprogress`, data);
}

