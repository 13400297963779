import * as types from '../constants/actionTypes';

export const getNestedTitles = sourceTypeId => {
	return {
		type: types.GET_NESTED_TITLES_REQUEST,
		sourceTypeId,
	};
};
export const getNestedTitlesSuccess = data => {
	return {
		type: types.GET_NESTED_TITLES_SUCCESS,
		payLoad: data,
	};
};
export const getNestedTitlesFailure = () => {
	return {
		type: types.GET_NESTED_TITLES_FAILURE,
	};
};

export const getCourses = examCategoryId => {
	return {
		type: types.GET_COURSES,
		examCategoryId,
	};
};

export const getCoursesSuccess = data => {
	return {
		type: types.GET_COURSES_SUCCESS,
		payLoad: data,
	};
};

export const getCoursesFailure = () => {
	return {
		type: types.GET_COURSES_FAILURE,
	};
};

export const getSubCourseTopics = postData => {
	return {
		type: types.GET_SUB_COURSE_TOPICS,
		postData,
	};
};

export const getSubCourseTopicsSuccess = data => {
	return {
		type: types.GET_SUB_COURSE_TOPICS_SUCCESS,
		payLoad: data,
	};
};

export const getSubCourseTopicsFailure = () => {
	return {
		type: types.GET_SUB_COURSE_TOPICS_FAILURE,
	};
};

export const checkLectureContent = id => {
	return {
		type: types.CHECK_LECTURE_CONTENT,
		id,
	};
};

export const checkLectureContentSuccess = data => {
	return {
		type: types.CHECK_LECTURE_CONTENT_SUCCESS,
		payLoad: data,
	};
};

export const checkLectureContentFailure = () => {
	return {
		type: types.CHECK_LECTURE_CONTENT_FAILURE,
	};
};

export const getAchievementVideos = id => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEOS,
		id,
	};
};

export const getAchievementVideosSuccess = data => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEOS_SUCCESS,
		payLoad: data,
	};
};

export const getAchievementVideosFailure = () => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEOS_FAILURE,
	};
};

export const getAchievementVideoUserProgress = postData => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEO_USER_PROGRESS,
		postData,
	};
};

export const getAchievementVideoUserProgressSuccess = data => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEO_USER_PROGRESS_SUCCESS,
		payLoad: data,
	};
};

export const getAchievementVideoUserProgressFailure = () => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEO_USER_PROGRESS_FAILURE,
	};
};

export const updateAchievementVideoUser = postData => {
	return {
		type: types.UPDATE_ACHIEVEMENT_VIDEO_USER,
		postData,
	};
};

export const updateAchievementVideoUserSuccess = data => {
	return {
		type: types.UPDATE_ACHIEVEMENT_VIDEO_USER_SUCCESS,
		payLoad: data,
	};
};

export const updateAchievementVideoUserFailure = () => {
	return {
		type: types.UPDATE_ACHIEVEMENT_VIDEO_USER_FAILURE,
	};
};

export const addAchievementVideoDuration = postData => {
	return {
		type: types.ADD_ACHIEVEMENT_VIDEO_DURATION,
		postData,
	};
};

export const addAchievementVideoDurationSuccess = data => {
	return {
		type: types.ADD_ACHIEVEMENT_VIDEO_DURATION_SUCCESS,
		payLoad: data,
	};
};

export const addAchievementVideoDurationFailure = () => {
	return {
		type: types.ADD_ACHIEVEMENT_VIDEO_DURATION_FAILURE,
	};
};


export const resetAllData = () => {
	return {
		type: types.RESET_ALL_DATA,
	};
}

export const getAchievementVideoReward = postData => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEO_REWARD,
		postData,
	};
}

export const getAchievementVideoRewardSuccess = data => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEO_REWARD_SUCCESS,
		payLoad: data,
	};
}

export const getAchievementVideoRewardFailure = () => {
	return {
		type: types.GET_ACHIEVEMENT_VIDEO_REWARD_FAILURE,
	};
}

export const getSubCourses = payLoad => {
	return {
		type: types.GET_SUB_COURSES,
		payLoad,
	};
}

export const getSubCoursesSuccess = data => {
	return {
		type: types.GET_SUB_COURSES_SUCCESS,
		payLoad: data,
	};
}

export const getSubCoursesFailure = () => {
	return {
		type: types.GET_SUB_COURSES_FAILURE,
	};
}

export const clearHomeworkData = () => {
	return {
		type: types.CLEAR_HOMEWORK_DATA,
	};
}