import { takeLatest, call, put } from 'redux-saga/effects';
import * as types from '../constants/actionTypes';
import * as BookApi from '../apis/book';
import { BookAction } from '../actions';
import { toast } from 'react-toastify';

export function* watcherBook() {
	yield takeLatest(types.GET_NESTED_TITLES_REQUEST, workerGetNestedTitles);
	yield takeLatest(types.GET_COURSES, workerGetCourses);
	yield takeLatest(types.GET_SUB_COURSE_TOPICS, workerGetSubCourseTopics);
	yield takeLatest(types.CHECK_LECTURE_CONTENT, workerCheckLectureContent);
	yield takeLatest(types.GET_ACHIEVEMENT_VIDEOS, workerGetAchievementVideos);
	yield takeLatest(types.GET_ACHIEVEMENT_VIDEO_USER_PROGRESS, workerGetAchievementVideoUserProgress);
	yield takeLatest(types.UPDATE_ACHIEVEMENT_VIDEO_USER, workerUpdateAchievementVideoUser);
	yield takeLatest(types.ADD_ACHIEVEMENT_VIDEO_DURATION, workerAddAchievementVideoDuration);
	yield takeLatest(types.GET_ACHIEVEMENT_VIDEO_REWARD, workerGetAchievementVideoReward);
	yield takeLatest(types.GET_SUB_COURSES, workerGetSubCourses);
}

function getNestedTitles(sourceTypeId) {
	return BookApi.getNestedTitles(sourceTypeId);
}

function* workerGetNestedTitles(action) {
	try {
		const response = yield call(getNestedTitles, action.sourceTypeId);
		localStorage.setItem('courses', JSON.stringify(response.data));
		yield put(BookAction.getNestedTitlesSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getNestedTitlesFailure());
	}
}

function getCourses(examCategoryId) {
	return BookApi.getCourses(examCategoryId);
}

function* workerGetCourses(action) {
	try {
		const response = yield call(getCourses, action.examCategoryId);
		yield put(BookAction.getCoursesSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getCoursesFailure());
	}
}

function getSubCourseTopics(postData) {
	return BookApi.getSubCourseTopics(postData);
}

function* workerGetSubCourseTopics(action) {
	try {
		const response = yield call(getSubCourseTopics, action.postData);
		yield put(BookAction.getSubCourseTopicsSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getSubCourseTopicsFailure());
	}
}

function checkLectureContent(id) {
	return BookApi.checkLectureContentv2(id);
}

function* workerCheckLectureContent(action) {
	try {
		const response = yield call(checkLectureContent, action.id);
		yield put(BookAction.checkLectureContentSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.checkLectureContentFailure());
	}
}

function getAchievementVideos(id) {
	return BookApi.getAchievementVideos(id);
}

function* workerGetAchievementVideos(action) {
	try {
		const response = yield call(getAchievementVideos, action.id);
		yield put(BookAction.getAchievementVideosSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getAchievementVideosFailure());
	}
}

function getAchievementVideoUserProgress(postData) {
	return BookApi.getAchievementVideoUserProgress(postData);
}

function* workerGetAchievementVideoUserProgress(action) {
	try {
		const response = yield call(getAchievementVideoUserProgress, action.postData);
		yield put(BookAction.getAchievementVideoUserProgressSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getAchievementVideoUserProgressFailure());
	}
}

function updateAchievementVideoUser(postData) {
	return BookApi.updateAchievementVideoUser(postData);
}

function* workerUpdateAchievementVideoUser(action) {
	try {
		const response = yield call(updateAchievementVideoUser, action.postData);
		yield put(BookAction.updateAchievementVideoUserSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.updateAchievementVideoUserFailure());
	}
}

function addAchievementVideoDuration(postData) {
	return BookApi.addAchievementVideoDuration(postData);
}

function* workerAddAchievementVideoDuration(action) {
	try {
		const response = yield call(addAchievementVideoDuration, action.postData);
		yield put(BookAction.addAchievementVideoDurationSuccess(response.data));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.addAchievementVideoDurationFailure());
	}
}

function getAchievementVideoReward(postData) {
	return BookApi.getAchievementVideoReward(postData);
}

function* workerGetAchievementVideoReward(action) {
	try {
		const response = yield call(getAchievementVideoReward, action.postData);
		yield put(BookAction.getAchievementVideoRewardSuccess(response.data));
		if (response.data.ResponseStatus !== 1) {
			toast.warn(response.data.ResponseMessage);
		}
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		} else {
			toast.error('Beklenmedik bir hata oluştu');
		}
		yield put(BookAction.getAchievementVideoRewardFailure());
	}
}

function getSubCourses(examCategoryId) {
	return BookApi.getSubCourses(examCategoryId);
}

function* workerGetSubCourses(action) {
	try {
		const response = yield call(getSubCourses, action.payLoad);
		yield put(BookAction.getSubCoursesSuccess(response.data?.ResponseData));
	} catch (error) {
		if (error && error.data && error.data.Error) {
			toast.error(error.data.Error.toString());
		}
		yield put(BookAction.getSubCoursesFailure());
	}
}