import * as types from '../constants/actionTypes';
import { sortBy } from 'lodash';
const initialState = {
	getTestSectionsV4: {
		loading: false,
		lastOne: false,
		data: [],
	},
	updateTestSectionElement: {
		loading: false,
		data: [],
	},
	updateTestSectionElementAndGetNextQuestion: {
		loading: false,
		data: [],
	},
	finishSectionV2: {
		loading: false,
		data: [],
	},
	createTestSectionV6: {
		loading: false,
		data: [],
	},
	addSolvedTest: {
		loading: false,
		showPopup: false,
		data: [],
	},
	createTestSectionElementV2: {
		loading: false,
		data: [],
	},
	getTestSectionElement: {
		loading: false,
		data: [],
	},
	getFinishedTestSectionSummary: {
		loading: false,
		data: [],
	},
	getTestSectionSummary: {
		loading: false,
		data: [],
	},
	getTestSectionCourseSummary: {
		loading: false,
		data: [],
	},
	getTestSectionSubCourseSummary: {
		loading: false,
		data: [],
	},
	getTestSectionSummary: {
		loading: false,
		data: [],
	},
	getTestSectionSummaryV3: {
		loading: false,
		data: [],
	},
	updateTestSectionV2: {
		loading: false,
		data: [],
	},
	deleteTestSection: {
		loading: false,
		data: [],
	},
	getUserScoreNet: {
		loading: false,
		data: [],
	},
	getAllUsersScoreNet: {
		loading: false,
		data: [],
	},
	getTestSectionElements: {
		loading: false,
		data: [],
	},
	getSubCourseSuccessLevel: {
		loading: false,
		data: [],
	},
	lastUnFinishedTest: {
		loading: false,
		data: null,
	},
};

const TestSection = (state = initialState, { type, payLoad }) => {
	switch (type) {
		case types.GET_TEST_SECTION_V4_REQUEST:
			return {
				...state,
				getTestSectionsV4: { ...state.getTestSectionsV4, loading: true },
			};
		case types.GET_TEST_SECTION_V4_SUCCESS:
			const categorized = payLoad.reduce((grouped, testSection) => {
				const date = testSection.ModifiedDate.split('T')[0];
				if (!grouped[date]) {
					grouped[date] = [];
				}
				grouped[date].push(testSection);
				return grouped;
			}, {});
			const categorizedArrays = Object.keys(categorized).map(date => {
				return {
					date,
					testSection: categorized[date],
				};
			});
			const _list = [...state.getTestSectionsV4.data, ...categorizedArrays];
			const sorted = sortBy(_list, o => o.date).reverse();
			return {
				...state,
				getTestSectionsV4: { data: sorted, lastOne: payLoad.length === 0, loading: false },
			};
		case types.GET_TEST_SECTION_V4_FAILURE:
			return {
				...state,
				getTestSectionsV4: { data: [], loading: false },
			};

		case types.UPDATE_TEST_SECTION_ELEMENT_REQUEST:
			return {
				...state,
				updateTestSectionElement: {
					...state.updateTestSectionElement,
					loading: true,
				},
			};
		case types.UPDATE_TEST_SECTION_ELEMENT_SUCCESS:
			return {
				...state,
				updateTestSectionElement: { data: payLoad, loading: false },
			};
		case types.UPDATE_TEST_SECTION_ELEMENT_FAILURE:
			return {
				...state,
				updateTestSectionElement: {
					...state.updateTestSectionElement,
					loading: false,
				},
			};

		case types.UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_REQUEST:
			return {
				...state,
				updateTestSectionElementAndGetNextQuestion: {
					...state.updateTestSectionElementAndGetNextQuestion,
					loading: true,
				},
			};
		case types.UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_SUCCESS:
			return {
				...state,
				updateTestSectionElementAndGetNextQuestion: { data: payLoad, loading: false },
			};
		case types.UPDATE_TEST_SECTION_ELEMENT_AND_GET_NEXT_QUESTION_FAILURE:
			return {
				...state,
				updateTestSectionElementAndGetNextQuestion: {
					data: [],
					loading: false,
				},
			};

		case types.FINISH_SECTION_V2_REQUEST:
			return {
				...state,
				finishSectionV2: { ...state.finishSectionV2, loading: true },
			};
		case types.FINISH_SECTION_V2_SUCCESS:
			return {
				...state,
				finishSectionV2: { data: payLoad, loading: false },
			};
		case types.FINISH_SECTION_V2_FAILURE:
			return {
				...state,
				finishSectionV2: {
					...state.finishSectionV2,
					loading: false,
				},
			};

		case types.CREATE_TEST_SECTION_V6_REQUEST:
			return {
				...state,
				createTestSectionV6: { ...state.createTestSectionV6, loading: true },
			};
		case types.CREATE_TEST_SECTION_V6_SUCCESS:
			return {
				...state,
				createTestSectionV6: { data: payLoad, loading: false },
			};
		case types.CREATE_TEST_SECTION_V6_FAILURE:
			return {
				...state,
				createTestSectionV6: {
					...state.createTestSectionV6,
					loading: false,
				},
			};

		case types.ADD_SOLVED_TEST_FROM_OUTSIDE_REQUEST:
			return {
				...state,
				addSolvedTest: {
					...state.addSolvedTest,
					loading: true,
				},
			};
		case types.ADD_SOLVED_TEST_FROM_OUTSIDE_SUCCESS:
			return {
				...state,
				addSolvedTest: { data: payLoad, showPopup: true, loading: false },
			};
		case types.ADD_SOLVED_TEST_FROM_OUTSIDE_FAILURE:
			return {
				...state,
				addSolvedTest: {
					showPopup: false,
					loading: false,
				},
			};

		case types.CREATE_TEST_SECTION_ELEMENT_V2_REQUEST:
			return {
				...state,
				createTestSectionElementV2: {
					...state.createTestSectionElementV2,
					loading: true,
				},
			};
		case types.CREATE_TEST_SECTION_ELEMENT_V2_SUCCESS:
			return {
				...state,
				createTestSectionElementV2: { data: payLoad, loading: false },
			};
		case types.CREATE_TEST_SECTION_ELEMENT_V2_FAILURE:
			return {
				...state,
				createTestSectionElementV2: {
					data: [],
					loading: false,
				},
			};

		case types.GET_TEST_SECTION_ELEMENT_REQUEST:
			return {
				...state,
				getTestSectionElement: {
					...state.getTestSectionElement,
					loading: true,
				},
			};
		case types.GET_TEST_SECTION_ELEMENT_SUCCESS:
			return {
				...state,
				getTestSectionElement: { data: payLoad, loading: false },
			};
		case types.GET_TEST_SECTION_ELEMENT_FAILURE:
			return {
				...state,
				getTestSectionElement: {
					data: [],
					loading: false,
				},
			};

		case types.GET_FINISHED_TEST_SECTION_SUMMARY_REQUEST:
			return {
				...state,
				getFinishedTestSectionSummary: {
					...state.getFinishedTestSectionSummary,
					loading: true,
				},
			};
		case types.GET_FINISHED_TEST_SECTION_SUMMARY_SUCCESS:
			return {
				...state,
				getFinishedTestSectionSummary: { data: payLoad, loading: false },
			};
		case types.GET_FINISHED_TEST_SECTION_SUMMARY_FAILURE:
			return {
				...state,
				getFinishedTestSectionSummary: {
					...state.getFinishedTestSectionSummary,
					loading: false,
				},
			};

		case types.GET_TEST_SECTION_COURSE_SUMMARY:
			return {
				...state,
				getTestSectionCourseSummary: {
					...state.getTestSectionCourseSummary,
					loading: true,
				},
			};
		case types.GET_TEST_SECTION_COURSE_SUMMARY_SUCCESS:
			return {
				...state,
				getTestSectionCourseSummary: { data: payLoad, loading: false },
			};
		case types.GET_TEST_SECTION_COURSE_SUMMARY_FAILURE:
			return {
				...state,
				getTestSectionCourseSummary: {
					...state.getTestSectionCourseSummary,
					loading: false,
				},
			};

		case types.GET_TEST_SECTION_SUB_COURSE_SUMMARY:
			return {
				...state,
				getTestSectionSubCourseSummary: {
					...state.getTestSectionSubCourseSummary,
					loading: true,
				},
			};
		case types.GET_TEST_SECTION_SUB_COURSE_SUMMARY_SUCCESS:
			return {
				...state,
				getTestSectionSubCourseSummary: { data: payLoad, loading: false },
			};
		case types.GET_TEST_SECTION_SUB_COURSE_SUMMARY_FAILURE:
			return {
				...state,
				getTestSectionSubCourseSummary: {
					...state.getTestSectionSubCourseSummary,
					loading: false,
				},
			};

		case types.GET_TEST_SECTION_SUMMARY_REQUEST:
			return {
				...state,
				getTestSectionSummary: {
					...state.getTestSectionSummary,
					loading: true,
				},
			};
		case types.GET_TEST_SECTION_SUMMARY_SUCCESS:
			return {
				...state,
				getTestSectionSummary: { data: payLoad, loading: false },
			};
		case types.GET_TEST_SECTION_SUMMARY_FAILURE:
			return {
				...state,
				getTestSectionSummary: {
					data: [],
					loading: false,
				},
			};
		case types.GET_TEST_SECTION_SUMMARY_REQUEST_V3:
			return {
				...state,
				getTestSectionSummaryV3: {
					...state.getTestSectionSummaryV3,
					loading: true,
				},
			};
		case types.GET_TEST_SECTION_SUMMARY_SUCCESS_V3:
			return {
				...state,
				getTestSectionSummaryV3: { data: payLoad, loading: false },
			};
		case types.GET_TEST_SECTION_SUMMARY_FAILURE_V3:
			return {
				...state,
				getTestSectionSummaryV3: {
					data: [],
					loading: false,
				},
			};

		case types.UPDATE_TEST_SECTION_V2_REQUEST:
			return {
				...state,
				updateTestSectionV2: {
					...state.updateTestSectionV2,
					loading: true,
				},
			};
		case types.UPDATE_TEST_SECTION_V2_SUCCESS:
			return {
				...state,
				updateTestSectionV2: { data: payLoad, loading: false },
			};
		case types.UPDATE_TEST_SECTION_V2_FAILURE:
			return {
				...state,
				updateTestSectionV2: {
					data: [],
					loading: false,
				},
			};

		case types.DELETE_TEST_SECTION_REQUEST:
			return {
				...state,
				deleteTestSection: {
					...state.deleteTestSection,
					loading: true,
				},
			};
		case types.DELETE_TEST_SECTION_SUCCESS:
			return {
				...state,
				deleteTestSection: { data: payLoad, loading: false },
			};
		case types.DELETE_TEST_SECTION_FAILURE:
			return {
				...state,
				deleteTestSection: {
					data: [],
					loading: false,
				},
			};

		case types.GET_USER_SCORE_NET:
			return {
				...state,
				getUserScoreNet: {
					...state.getUserScoreNet,
					loading: true,
				},
			};

		case types.GET_USER_SCORE_NET_SUCCESS:
			return {
				...state,
				getUserScoreNet: { data: payLoad, loading: false },
			};

		case types.GET_USER_SCORE_NET_FAILURE:
			return {
				...state,
				getUserScoreNet: {
					data: [],
					loading: false,
				},
			};

		case types.GET_ALL_USERS_SCORE_NET:
			return {
				...state,
				getAllUsersScoreNet: {
					...state.getAllUsersScoreNet,
					loading: true,
				},
			};

		case types.GET_ALL_USERS_SCORE_NET_SUCCESS:
			return {
				...state,
				getAllUsersScoreNet: { data: payLoad, loading: false },
			};

		case types.GET_ALL_USERS_SCORE_NET_FAILURE:
			return {
				...state,
				getAllUsersScoreNet: {
					data: [],
					loading: false,
				},
			};

		case types.GET_TEST_SECTION_ELEMENTS:
			return {
				...state,
				getTestSectionElements: {
					...state.getTestSectionElements,
					loading: true,
				},
			};

		case types.GET_TEST_SECTION_ELEMENTS_SUCCESS:
			return {
				...state,
				getTestSectionElements: { data: payLoad, loading: false },
			};

		case types.GET_TEST_SECTION_ELEMENTS_FAILURE:
			return {
				...state,
				getTestSectionElements: {
					data: [],
					loading: false,
				},
			};

		case types.GET_SUB_COURSE_SUCCESS_LEVEL:
			return {
				...state,
				getSubCourseSuccessLevel: {
					...state.getSubCourseSuccessLevel,
					loading: true,
				},
			};

		case types.GET_SUB_COURSE_SUCCESS_LEVEL_SUCCESS:
			return {
				...state,
				getSubCourseSuccessLevel: { data: payLoad, loading: false },
			};

		case types.GET_SUB_COURSE_SUCCESS_LEVEL_FAILURE:
			return {
				...state,
				getSubCourseSuccessLevel: {
					data: [],
					loading: false,
				},
			};

			case types.GET_LAST_UNFINISHED_TEST:
			return {
				...state,
				lastUnFinishedTest: {
					...state.lastUnFinishedTest,
					loading: true,
				},
			};

		case types.GET_LAST_UNFINISHED_TEST_SUCCESS:
			return {
				...state,
				lastUnFinishedTest: { data: payLoad, loading: false },
			};

		case types.GET_LAST_UNFINISHED_TEST_FAILURE:
			return {
				...state,
				lastUnFinishedTest: {
					data: null,
					loading: false,
				},
			};

		default:
			return state;
	}
};

export default TestSection;
